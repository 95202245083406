.about-page {
    background-color: rgb(17, 17, 17);
    margin: 0;
    height: 100vh;
    width: 100%;
    overflow: auto;
}

@media screen and (max-width: 800px) {
    .about-page {
        height: 100vh;
        width: 100%;
        overflow: auto;
    }
}