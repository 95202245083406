
.about {
    font-weight: 200;
    padding-top: 75px;
    color: rgb(192, 192, 192);
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 70px;
    text-align: center;
    z-index: 20;
}

.spacer {
    height: 50px;
}

.about-content {
    display: flex;
    width: 95vw;
    height: 500px;
    margin-top: 40px;
    z-index: 20;
}

.about-bio{
    padding-top: 12px;
    color: rgb(192, 192, 192);
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    width: 55%;
    z-index: 20;
}

.subheadings{
    padding-top: 5px;
    line-height: 1.6;
}

.subheadings p {
    padding-top: 15px;
}

.title {
    padding-top: 20px;
    font-size: 2.1rem;
}

.title p {
    padding-bottom: 15px;
}



@media screen and (max-width: 800px) {

    .about {
        margin: 0 auto;

    }
    .about-bio {
        width: 100%;
        position: relative;
        margin-bottom: 100px;
        padding-bottom: 100px;
        text-align: center;
    }

     .about-content {
        width: 100vw;
        position: relative;
        height: 100%; 
        display: grid;
        grid-template-columns: 80%;
        grid-template-rows: 450px 400px;
        justify-content: center;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 25px;
    } 
}