.social-icons  {
    font-size: 1.1rem;
    color: rgb(105, 105, 105);
    text-align: center;
    margin-top: 170px;
    text-decoration: none;
}

.individual-icon {
    padding: 0 4px;
}

.sm-icon:link, .sm-icon:visited {
    color: rgb(105, 105, 105);
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
  