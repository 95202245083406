.NavbarItems {
    background: linear-gradient(90deg,#555c5c 0%, rgb(77, 77, 77) 100%);
    height: 60px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}


.navbar-logo {
    color: rgb(192, 192, 192);
    justify-content: left;
    margin-left: 12px;
    cursor: pointer;
    text-decoration: none;
}

.fa-power-off {
    margin-left: 0.5rem;
    font-size: 1.4rem;
    color: rgb(117, 119, 119);
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: .5rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    color: #acacac;
    border-radius: 4px;
    transition: all 0.2s ease-out;

}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 990px) {

    .fa-power-off {
        margin-left: 0.5rem;
        font-size: 1.1rem;
    }

    .NavbarItems {
        font-size: .95rem;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 300px;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

    }

    .nav-menu.active {
        background: #757575;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 100;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: rgb(219, 219, 219);

    }

    .nav-links:hover {
        background-color: #8f8f8f;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0%,50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 35%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

}