.bio {
    text-align: center;
    color: #c0c0c0;
    margin: auto;
    width: 50%;
    margin-top: 210px;
    padding: 10px;
}

.name-title {
    font-size: 2.7rem;
    font-weight: 200;
}

.name-subtitle {
    font-size: 1rem;
    font-weight: 200;
}
