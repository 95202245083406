.pic-container {
    padding: 15px 5px 0px 60px;
    height: 500px;
    width: 500px;
    float: left;
}

.profile-pic {
    height: auto;
    max-width: 300px;
    border-radius: 40%;
    width: 70%;
}

@media screen and (max-width: 800px) {

    .pic-container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: none;
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .profile-pic {
        width: 500px;
    }
    
}
